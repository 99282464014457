import styled, { css } from 'styled-components';
import { Wrapper as Button } from '../form/button/button.styled';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{
    show?: boolean;
}>`
    ${({ theme, show }) => css`
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        padding: 1rem;
        transition: all ${theme.base.transition};
        visibility: hidden;
        z-index: 1000;
        backdrop-filter: blur(0.5rem);
        align-items: center;
        display: flex;
        justify-content: center;

        ${show &&
        css`
            opacity: 1;
            visibility: visible;
        `}

        @media ${theme.breakpoints.md.min} {
            padding: 2rem;
        }
    `}
`;

export const Overlay = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{
    show?: boolean;
}>`
    ${({ theme }) => css`
        background-color: ${theme.modal.overlay.background};
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1100;
    `}
`;

export const Window = styled.div.withConfig({
    shouldForwardProp: (prop) => !['locked', 'width', 'align'].includes(prop),
})<{
    locked: boolean;
    width?: number;
    align?: 'left' | 'right' | 'center';
}>`
    ${({ theme, locked, width, align }) => css`
        background-color: ${theme.modal.window.background};
        max-width: ${width ? `${width}rem` : '56rem'};
        position: relative;
        width: 100%;
        z-index: 1200;
        max-height: 100%;
        overflow: auto;
        border-radius: ${theme.radius.medium};

        @media ${theme.breakpoints.md.min} {
            height: unset;
        }

        ${(locked || align === 'center') &&
        css`
            text-align: center;
        `}
    `}
`;

export const Title = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.headline.size.h3.fontSize};
        line-height: ${theme.headline.size.h3.lineHeight};
        color: ${theme.palette.common.text};
        font-weight: 700;
        padding: 2rem;

        @media ${theme.breakpoints.md.min} {
            padding: 3.2rem;
        }
    `}
`;

export const Close = styled.div.withConfig({
    shouldForwardProp: (prop) => !['locked'].includes(prop),
})<{
    locked: boolean;
}>`
    ${({ theme, locked }) => css`
        cursor: pointer;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;

        i {
            color: ${theme.modal.close.base.color};
            height: 2rem;
            transition: color ${theme.base.transition};
            width: 2rem;
        }

        &:hover {
            i {
                color: ${theme.modal.close.hover.color};
            }
        }

        ${locked &&
        css`
            i {
                color: ${theme.palette.color.white.main};
            }

            &:hover {
                i {
                    color: ${theme.palette.color.white.main};
                }
            }
        `}
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        padding: 0 2rem;
        overflow-x: hidden;

        @media ${theme.breakpoints.md.min} {
            padding: 0 3.2rem;
        }
    `}
`;

export const Footer = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;

        @media ${theme.breakpoints.md.min} {
            padding: 3.2rem;
        }

        ${Button}:only-child {
            margin-left: auto;
        }
    `}
`;
