import React from 'react';
import { Loading } from '../loading/loading';
import { Wrapper, Window, Overlay, Close, Footer, Title, Content } from './modal.styled';
import { IconClose } from '../icons/close';
import { useEscapeClose } from '../../../hooks/use-escape-close';
import { ModalAlerts } from '../alerts/modal/alerts';

type ModalType = {
    children: React.ReactNode;
    show?: boolean;
    title?: string | React.ReactNode;
    footer?: React.ReactNode;
    onClose?: () => void;
    loading?: boolean;
    locked?: boolean;
    width?: number;
    align?: 'left' | 'right' | 'center';
    showCloseButton?: boolean;
};

export const Modal: React.FC<ModalType> = ({
    show = false,
    title,
    children,
    onClose,
    footer,
    loading = false,
    locked = false,
    width,
    align,
    showCloseButton = true,
}: ModalType) => {
    useEscapeClose(show, onClose);

    return show ? (
        <Wrapper show={show}>
            <Window align={align} locked={locked} width={width}>
                <ModalAlerts />

                <Loading active={loading}>
                    <Title>{title}</Title>
                    <Content>{children}</Content>
                    {footer && <Footer>{footer}</Footer>}
                </Loading>
                {showCloseButton && (
                    <Close locked={locked} onClick={onClose}>
                        <IconClose />
                    </Close>
                )}
            </Window>
            <Overlay />
        </Wrapper>
    ) : null;
};
