import React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '../modal/modal';

export interface BaseDialogInterface {
    opened: boolean;
    close: () => void;
    title?: string | React.ReactNode;

    content: React.ReactElement;
    action?: React.ReactElement;
    loading?: boolean;
    locked?: boolean;
    width?: number;
    align?: 'left' | 'right' | 'center';
    showCloseButton?: boolean;
}

export const BaseDialog: React.FC<BaseDialogInterface> = observer(
    ({ width, opened, align, close, title, content, showCloseButton = true, action, loading, locked }) => {
        return (
            <Modal
                show={opened}
                title={title}
                onClose={close}
                footer={action}
                loading={loading}
                locked={locked}
                width={width}
                align={align}
                showCloseButton={showCloseButton}
            >
                {content}
            </Modal>
        );
    },
);
